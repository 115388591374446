@import '../../scss/variables';

.loading{
  &-container__div{
    padding: 5px;
    margin: 50px auto;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
  }
  &-material__circularprogress{
    width: 80px !important;
    height: 80px !important;
  }
}