p, img, div, body, a, li, ul, section, button{
  padding: 0;
  border: none;
  margin: 0;
  font-family: "Trade Gothic Lt Std", "Proxima Nova, sans-serif", Arial, Helvetica;
  // box-sizing: border-box;
}

h2, h3{
  margin: 5px;
  font-family: "Trade Gothic Lt Std", "Proxima Nova, sans-serif", Arial, Helvetica;
}

h2{
  font-size: 18px;
}

h3{
  font-size: 15px;
  font-weight: 600;
}