@import '../../scss/variables';

.customerinfo{
  &-content__div{
    padding: 16px;
  }
  &-header__h1{
    font-size: 22px;
    padding: 5px;
  }
  &-form__form{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-input__textfield{
    padding: 5px;
  }

  &-border__div{
    height: 2px;
    width: 85%;
    background-color: $default-border-gray;
    margin: 25px auto;
  }

  &-searchterm__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
    padding: 5px;
    margin: 10px 0;
  }

  &-searchterm__p{
    font-family: inherit;
    font-size: inherit;
  }

  &-searchterm-header__span{
    font-weight: 600;
  }

  &-info__div{
    padding: 5px;
  }

  &-details__div{
    font-family: $default-sans-serif-font;
    font-size: 15px;
  }

  &-details-noresult__div{
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    color: $default-red;
  }

  &-details__p{
    font-family: inherit;
    font-size: inherit;
    margin: 5px 0;
  }

  &-details-header__span{
    font-weight: 600;
  }

  @media only screen and (min-width: 767px) {
    &-form__form{
      justify-content: flex-start;
    }
  }
}