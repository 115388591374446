@import '../../scss/variables';

.login{
  &-appbar-center__div{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
  &-logo__div{
    width: 100px;
    padding: 5px;
  }

  &-logo__img{
    width: 100%;
  }

  &-error__div{
    display: flex;
    flex-flow: row wrap;
    padding: 5px;
    margin: 10px 0;
    font-size: 15px;
    font-family: $default-sans-serif-font;
    align-items: center;
    color: $default-red;
  }

  &-error-msg__p{
    padding: 5px;
    font-family: inherit;
    font-size: inherit;
  }

  &-form__div{
    max-width: 500px;
    margin: 50px auto;
    padding: 10px;
  }

  &-form__form{
    display: flex;
    flex-flow: column wrap;
  }

  &-submit-btn__div{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    width: 150px;
    margin: 15px auto;
  }
}