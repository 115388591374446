@import '../../scss/variables';

.orders{
  &-header__h2{
    margin: 25px 15px;
  }

  &-table__div{
    max-width: 95%;
    margin: 50px auto;
  }
}